import React, { useEffect, useState } from 'react'
import photo from '../../Assets/photoUser.jpg'
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {Modal} from 'antd'
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled, FilterOutlined} from '@ant-design/icons'
import { api } from '../../api';
import userDefaut from '../../../src/Assets/user2.png'

const Header = ({titre}) => {

  const navigate = useNavigate()

  const token = sessionStorage.getItem('token');
  const nomUser = sessionStorage.getItem('nomUser');
  const prenomUser = sessionStorage.getItem('prenomUser');
  const photo = sessionStorage.getItem('photoUser');

  const [userInfo, setUserInfo] = useState(null);
  const [nom, setNom] = useState('........');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('......');
  const [photoUser, setPhotoUser] = useState();


  // const [nom, setNom] = useState(null);
  // const [prenom, setPrenom] = useState(null);
  // No

  const toProfil = () => {
    navigate('/admin/profil')
  }

  const logout = async () => {
    try {
      const response = await axios.post(api + '/api/deconnexion', {}, {
        headers: {
          Authorization: `Bearer ${token}`, // Assurez-vous que vous envoyez le bon token
        },
      });
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('admin_id');
      navigate('/connexion')
      console.log(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(api + '/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserInfo(response.data);
        setNom(response.data.nom); setPrenom(response.data.prenom);
        setEmail(response.data.email)
        setPhotoUser(response.data.photo)
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, [token]);

  const { confirm } = Modal;

  // Modal de confirmation d'ajout
  const showConfirmLogout = () => {
    confirm({
      title: 'Voulez-vous vraiment se déconnecter ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, se déconnecter', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'red', borderColor: 'red', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {

        logout()
        
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  // const logout = async () => {
  //   // navigate('/connexion')
  //   try {

  //     console.log('Déconnexion réussie')
  //     navigate('/connexion')
      
  //   } catch (err) {
  //     // Gérer les erreurs de connexion
  //     console.log(err)
  //   } finally {
  //     // setLoading(false); // Désactiver l'indicateur de chargement
  //   }
  // }

  const items =  [
    {
      label: <p onClick={toProfil} className='f font-semibold hover:text-primary'><UserOutlined className='pr-2'/>Mon profil</p>,
      key: '0',
    },
    {
      label: <p className='f font-semibold text-red-600' onClick={showConfirmLogout}><LogoutOutlined className='pr-2'/>Se déconnecter</p>,
      key: '1',
    },
  ];

  return (
    <div className="ml-8 mr-7 py-2 px-4 bg-blue-200 shadow-md rounded-lg mt-1 flex justify-between items-center space-x-4">
        <div className="font-bold text-xl text-gray-800">
          {titre}
        </div>
        <div className="flex items-center space-x-4">
          <div>
            <img
              src={`${api}/storage/${photo}`}
              alt="User"
              className="w-10 h-10 object-cover rounded-full shadow-sm"
            />
          </div>
          <div className="text-center font-medium text-gray-700">
            <Dropdown
              menu={{
                items,
              }}
              trigger={['click']}
            >
              <a
                onClick={(e) => e.preventDefault()}
                className="text-gray-800 font-medium flex items-center space-x-2"
              >
                <Space>
                  <span className="cursor-pointer text-base hover:text-blue-500">
                    {nomUser} {prenomUser} <DownOutlined className="w-3 h-3" />
                  </span>
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>

  )
}

export default Header