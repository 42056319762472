import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { api } from '../../../api';
import { Empty } from 'antd';

// Enregistrement des éléments nécessaires dans ChartJS
ChartJS.register(ArcElement, Tooltip, Legend);

const ChartCercle = () => {

  const [aboMensuel, setAboMensuel] = useState(0);
  const [aboAnnuel, setAboAnnuel] = useState(0);

  const getTotalNombre = async () => {
    try {
      const response = await axios.get(api + '/api/getTotalAboMensuelAnnuel');
      setAboAnnuel(response.data.totalAboAnnuel);
      setAboMensuel(response.data.totalAboMensuel);
      // console.log(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  useEffect(() => {
    getTotalNombre()
  }, []);

  const data = {
    labels: ['Mensuel', 'Annuel'],
    datasets: [
      {
        label: '# of Votes',
        data: [aboMensuel, aboAnnuel],
          backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right', // Position des légendes
        align: 'center',  // Alignement vertical
      },
      tooltip: {
        callbacks: {
          // Affichage de la valeur exacte dans le tooltip
          label: (tooltipItem) => {
            const value = tooltipItem.raw; // Valeur brute de l'élément
            return `${tooltipItem.label}: ${value}`;
          },
        },
      },
    },
  };
  

  return  (
    <div className="w-full p-1 mt-2 pb-2 bg-white border border-gray-200 shadow-md rounded-lg">
      
      <div className="w-full h-96 flex justify-center items-center">
        <Pie data={data} options={options} />
      </div>
    </div>

    // <Pie data={data} options={options} />
  )
};

export default ChartCercle;
